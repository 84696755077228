import React from 'react'
import { graphql, Link } from 'gatsby'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import './all-tags-index.css'

class AllTagsTemplate extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const { tagCounts } = this.props.pageContext
    const tagsArr = Object.entries(tagCounts)
      .sort(([, a], [, b]) => a - b)
      .reverse()

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Browse by tags"
          description="Browse Rope and Tire using tags"
        />
        <h1>Tags</h1>
        <hr />

        <ul className="alltags-list">
          {tagsArr.map((item, index) => {
            const tagName = item[0]
            const tagCount = item[1]
            return (
              <li key={index} className="alltags-tag">
                <Link to={`/tags/${tagName}`} className="alltags-tag-link">
                  {tagName} ({tagCount})
                </Link>
              </li>
            )
          })}
        </ul>
      </Layout>
    )
  }
}

export default AllTagsTemplate

export const pageQuery = graphql`
  query AllTagsIndex {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
